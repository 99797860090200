import React from "react";
import "./App.css";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation();

  return (
    <div className="app about">
      <video
        poster="fishing.jpg"
        alt="me"
        className="avatar moving"
        src="fishing.mp4"
        autoPlay
        playsInline
        muted
        loop
      />
      <div className="terminal">
        <div className="bar">
          <div className="btn"></div>
          <div className="terminal-tabs">
            <div className="terminal-tab selected">yanis@localhost:~</div>
          </div>
        </div>
        <div className="body">
          <div className="prompt">
            <Trans
              t={t}
              i18nKey="about.content"
              data-i18n="[html]about.content"
              components={[
                <span className="country" />,
                <span className="not available" />,
                <Link style={{ color: "white" }} to="/" />,
              ]}
            />
            <span className="pulse"></span>
            <br />
            <br />
            <div className="links">
              <a
                style={{ color: "white" }}
                href="https://www.linkedin.com/in/ykedin"
                target="_blank"
                rel="noreferrer"
              >
                Linkedin
              </a>
              {" | "}
              <a
                style={{ color: "white" }}
                href="http://twitter.com/yanouhd"
                target="_blank"
                rel="noreferrer"
              >
                Twitter
              </a>
              {" | "}
              <a
                style={{ color: "white" }}
                href="https://apps.apple.com/us/developer/yanis-kerriou/id1562160950"
                target="_blank"
                rel="noreferrer"
              >
                App Store
              </a>
              {" | "}
              <a
                style={{ color: "white" }}
                href="mailto:yanis.kerriou@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                E-mail
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
