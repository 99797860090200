import React from "react";
import "../App.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useTheme from "../hooks/useTheme";

export default function NavBar({ routes }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  useTheme();

  const languageCondition = (fr, en) =>
    i18n.resolvedLanguage === "en" ? fr : en;

  return (
    <div className="navbar">
      <img
        src="line.3.horizontal.svg"
        alt="navbar-menu"
        className="navbar-select-icon"
      />
      <select
        value={pathname}
        onChange={(e) => navigate(e.target.value)}
        className="navbar-select-mobile"
      >
        {routes.map((r) => (
          <option value={r.path} key={r.name}>
            {t(`navbar.${r.name}`)}
          </option>
        ))}
      </select>
      <div className="navbar-buttons">
        {routes.map((r) => (
          <Link
            key={r.name}
            to={r.path}
            className={`navbar-button ${
              r.path === pathname && "navbar-button-active"
            }`}
          >
            {t(`navbar.${r.name}`)}
          </Link>
        ))}
        <div
          onClick={() => i18n.changeLanguage(languageCondition("fr", "en"))}
          className="navbar-button lang"
        >
          {languageCondition("🇫🇷", "🇬🇧")}
        </div>
      </div>
    </div>
  );
}
