import { useEffect } from "react";

export default function useTheme() {
  useEffect(() => {
    if (!window.matchMedia) {
      document.body.classList.add("dark");
      return;
    }

    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );

    const applyTheme = (isDarkMode) => {
      if (isDarkMode) {
        document.body.classList.add("dark");
      } else {
        document.body.classList.remove("dark");
      }
    };

    applyTheme(darkModeMediaQuery.matches);

    const listener = (e) => {
      applyTheme(e.matches);
    };

    darkModeMediaQuery.addEventListener("change", listener);

    return () => {
      darkModeMediaQuery.removeEventListener("change", listener);
    };
  }, []);
}
