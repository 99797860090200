import React, { useEffect, useState } from "react";

export default function MobileApp(props) {
  const [mouseOver, setMouseOver] = useState(false);

  const handleMouseEvent = (over) => {
    if (mouseOver !== over) {
      setMouseOver(over);
    }
  };

  /**
   * Preload preview images
   */
  useEffect(() => {
    const img = new Image();
    img.src = `${props.slug}-preview.jpg`;
  }, [props.slug]);

  return (
    <div
      style={props.style}
      onMouseOver={() => handleMouseEvent(true)}
      onMouseOut={() => handleMouseEvent(false)}
      className="mobile-app"
    >
      <div
        className={mouseOver ? "app-preview-div" : "app-icon-div"}
        style={{ backgroundColor: props.color }}
      >
        {mouseOver ? (
          props.noVideo ? (
            <img
              onClick={props.onClick}
              alt={props.alt}
              className="app-preview"
              src={`${props.slug}-preview.jpg`}
            />
          ) : (
            <video
              onClick={props.onClick}
              poster={`${props.slug}-preview.jpg`}
              alt={props.alt}
              className="app-preview"
              src={`${props.slug}.mp4`}
              playsInline
              autoPlay
              muted
              loop
            />
          )
        ) : (
          <img alt={props.alt} className="app-icon" src={`${props.slug}.png`} />
        )}
      </div>
      <p className="app-name">{props.name}</p>
    </div>
  );
}
