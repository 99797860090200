import React from "react";
import { useTranslation } from "react-i18next";

export default function BigTooltip(props) {
  const { t } = useTranslation();

  return (
    <div className="big-tooltip-container">
      <div
        className="tooltip-button"
        onClick={() => window.open(props.link, "_blank")}
      >
        <img
          alt="building-icon"
          className="tooltip-icon"
          src="building.2.svg"
        />
        <span>{props.name}</span>
      </div>
      <div
        className="tooltip-button"
        onClick={() => window.open(props.stackLink, "_blank")}
      >
        <img alt="hammer-icon" className="tooltip-icon" src="hammer.svg" />
        <span>{props.stack}</span>
      </div>
      <div
        className="tooltip-button"
        onClick={() => window.open(props.locationLink, "_blank")}
      >
        <img alt="location-icon" className="tooltip-icon" src="location.svg" />
        <span>{props.location}</span>
      </div>
      <div className="tooltip-separator" />
      <div className="tooltip-button" onClick={props.more}>
        <img alt="info-icon" className="tooltip-icon" src="info.circle.svg" />
        <span>{t("home.more")}</span>
      </div>
    </div>
  );
}
