import React from "react";
import BigTooltip from "./BigTooltip";

export default function ProfessionalIcon(props) {
  return (
    <div style={props.style} className="mobile-app">
      <BigTooltip
        name={props.name}
        stack={props.stack}
        location={props.location}
        link={props.link}
        stackLink={props.stackLink}
        locationLink={props.locationLink}
        more={props.onClick}
      />
      <div
        className="app-icon-div"
        onClick={props.onClick}
        style={{ backgroundColor: props.color }}
      >
        <img
          alt={props.alt}
          className="pro-app-icon"
          src={`${props.slug}.svg`}
        />
      </div>
      <p className="app-name">{props.name}</p>
    </div>
  );
}
