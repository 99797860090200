import React from "react";
import "./App.css";
import { useTranslation } from "react-i18next";

function Testimonials() {
  const testimonials = [
    {
      name: "Matthieu Echalier",
      role: "gac1.role",
      company: "gac.company",
      icon: "gac-technology.svg",
      linkWord: "of",
      photo: "1517774512539.jpeg",
      comment: "gac1.comment",
    },
    {
      name: "Rongjun Xue",
      role: "bnc1.role",
      company: "bnc.company",
      icon: "bnc.svg",
      linkWord: "at",
      photo: "1516781095844.jpeg",
      comment: "bnc1.comment",
    },
    {
      name: "Floris Calori",
      role: "ltv1.role",
      company: "ltv.company",
      icon: "ltv.jpg",
      linkWord: "of",
      photo: "1517611454907.jpeg",
      comment: "ltv1.comment",
    },
  ];

  const { t } = useTranslation();

  return (
    <div className="app testimonials">
      {testimonials.map((testimonial) => (
        <div key={testimonial.role} className="testimonial-container">
          <div className="testimonial-info">
            <div className="testimonial-avatar-container">
              <img
                src={testimonial.photo}
                alt={testimonial.name}
                className="testimonial-avatar"
              />
              <img
                src={testimonial.icon}
                alt={testimonial.name}
                className="testimonial-icon"
              />
            </div>
            <span className="testimonial-name">{testimonial.name}</span>
            <span className="testimonial-role">
              {t(`testimonials.${testimonial.role}`)}
              {t(`testimonials.${testimonial.linkWord}`)}
              {t(`testimonials.${testimonial.company}`)}
            </span>
          </div>
          <span className="testimonial-comment">
            {t(`testimonials.${testimonial.comment}`)}
          </span>
        </div>
      ))}
    </div>
  );
}

export default Testimonials;
