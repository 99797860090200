import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { useLocation } from "react-router-dom";
import rehypeRaw from "rehype-raw";

export default function Resume() {
  const location = useLocation();
  const [tab, setTab] = useState(location.state?.tab || 0);
  const { t } = useTranslation();

  const tabs = ["gac-technology", "usmb", "bnc", "d4"];

  return (
    <div className="app resume">
      <div className="terminal-resume-container">
        <div className="terminal-resume">
          <div className="bar resume-bar">
            <div className="btn"></div>
            <div className="terminal-tabs">
              {tabs.map((ta, i) => (
                <div
                  key={i}
                  onClick={() => {
                    setTab(i);
                    document.getElementsByClassName(
                      "terminal-resume"
                    )[0].scrollTop = 0;
                  }}
                  className={`terminal-tab ${tab === i && "selected"}`}
                >
                  <span className="tab-logo">
                    <img
                      alt="tab-icon"
                      className="tab-icon"
                      src={`${ta}.svg`}
                    />
                  </span>
                  <span className="terminal-tab-title">
                    {t(`resume.${ta}.name`)}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="body resume-terminal">
            <div className="company-logo">
              <img
                alt="company-logo"
                className="company-icon"
                src={`${tabs[tab]}.svg`}
              />
            </div>
            <div className="prompt-resume">
              <span className="resume-title">
                <span style={{ color: "#20C5C6" }}>◈</span>{" "}
                {t(`resume.${tabs[tab]}.name`)}{" "}
                <span style={{ color: "#20C5C6" }}>◈</span>
                <br />
                {t(`resume.${tabs[tab]}.subtitle`)}
              </span>
              <br />
              <div className="prompt-content">
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={t(`resume.${tabs[tab]}.content`)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
