import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import NavBar from "./components/NavBar";
import Home from "./Home";
import About from "./About";
import Resume from "./Resume";
import Testimonials from "./Testimonials";

const routes = [
  {
    name: "home",
    path: "/",
    element: <Home />,
  },
  {
    name: "about",
    path: "/about",
    element: <About />,
  },
  {
    name: "resume",
    path: "/resume",
    element: <Resume />,
  },
  {
    name: "testimonials",
    path: "/testimonials",
    element: <Testimonials />,
  },
];

const App = () => {
  const location = useLocation();

  return (
    <>
      <NavBar routes={routes} />
      <TransitionGroup component={null}>
        <CSSTransition key={location.pathname} classNames="fade" timeout={300}>
          <Routes location={location}>
            {routes.map((r) => (
              <Route key={r.name} path={r.path} element={r.element} />
            ))}
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

export default App;
